@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Roboto:wght@400;700&display=swap');

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

/* Container Styles */
.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Table Styles */
.table-container {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  overflow-x: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Añadir bordes redondeados */
  border: 1px solid #ddd; /* Añadir un borde para dar estructura */
}

.table-header {
  background-color: #6a1b9a; /* Violeta */
  color: #fff;
}

.table-row {
  cursor: pointer;

  &:nth-of-type(odd) {
    background-color: #f3e5f5; /* Violeta claro */
  }

  &:nth-of-type(even) {
    background-color: #e1bee7; /* Violeta más claro */
  }

  &:hover {
    background-color: #d1c4e9; /* Violeta medio */
  }
}

/* Button Styles */
.button-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

button {
  background-color: #6a1b9a; /* Violeta */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    background-color: #4a148c; /* Violeta oscuro */
  }
}

/* Title Styles */
.page-title,
.main-title {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.role-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Status Button Styles */
.status-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.status-button {
  background-color: #D3D3D3;
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  margin: 5px;
  padding: 10px 20px;
  text-transform: uppercase;

  &.selected {
    background-color: var(--status-color); /* Usar la variable de color según el estado */
  }

  &:hover {
    background-color: #A9A9A9;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .page-title,
  .main-title {
    font-size: 1.5rem;
  }

  .role-title {
    font-size: 1.25rem;
  }

  .table-container {
    margin-top: 10px;
  }
}

/* Form Styles */
.filterForm,
.filter-form {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .filterField {
    margin-right: 10px;
  }

  .filterButton {
    margin-top: 10px;
  }

  
}

/* Permission Manager */
.permission-manager {
  padding: 20px;
  margin-left: 40px; // Añadimos un margen igual al ancho del Sidebar
  width: calc(100% - 250px); // Ajustamos el ancho para evitar desbordamientos

  .tableHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}


/* Service Status */
.serviceStatus {
  padding: 20px;

  h1 {
    margin-bottom: 20px;
  }

  .filter {
    margin-bottom: 20px;
  }
}

/* Report Styles */
.report-container {
  padding: 20px;
}

.report-table {
  width: 100%;
}

.generate-pdf-button {
  margin-top: 20px;
  text-align: center;
}

/* Specific Table Styles */
.pending-users-table .tableRow,
.tableRow {
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.driverLocationMap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}

/*.mapContainer {
  flex: 3;
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
  z-index: 0;
}*/

.servicesContainer {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
}

/* Personalizar el cursor en el mapa */
.leaflet-container {
  cursor: crosshair; /* Cambia el cursor a una mira */
}

/* Cambiar el cursor cuando se está arrastrando el mapa */
.leaflet-grab, .leaflet-grabbing {
  cursor: grab !important;
}

/* Nuevos estilos para Expenses */

/* Centrado del título */
.page-title {
  text-align: center;
  margin-bottom: 20px;
}

/* Diseño responsivo para la tabla */
@media (max-width: 768px) {
  .expense-table {
    overflow-x: auto;
  }
}

/* Estilo para los íconos en la tabla */
.table-row-icon {
  display: flex;
  justify-content: center;
}

/* Formulario de filtros responsivo */
.filter-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .MuiFormControl-root {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
}

.custom-privacy-policy-container {
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.custom-privacy-policy-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.custom-privacy-policy-subtitle {
  margin-top: 20px;
  font-weight: 600;
  color: #1976d2;
}

.custom-privacy-policy-text {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-privacy-policy-container {
    padding: 20px 10px;
  }

  .custom-privacy-policy-title {
    font-size: 1.8rem;
  }

  .custom-privacy-policy-subtitle {
    font-size: 1.3rem;
  }
}

.custom-legal-notice-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.custom-legal-notice-paper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.custom-legal-notice-box {
  padding: 20px;
}

.custom-legal-notice-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.custom-legal-notice-section-title {
  margin-top: 20px;
  font-weight: 600;
  color: #1976d2;
}

.custom-legal-notice-text {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-legal-notice-container {
    padding: 10px;
  }

  .custom-legal-notice-paper {
    padding: 15px;
  }

  .custom-legal-notice-title {
    font-size: 1.8rem;
  }

  .custom-legal-notice-section-title {
    font-size: 1.2rem;
  }
}


/* Cookie Policy Styles */
.cookie-policy-container {
  padding: 20px;
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.cookie-policy-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.cookie-policy-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1976d2;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cookie-policy-text {
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.cookie-policy-brand-name {
  font-weight: bold;
}

.cookie-policy-list {
  list-style-type: disc;
  margin-left: 20px;
}

.cookie-policy-contact-link {
  color: #1976d2;
  text-decoration: none;
}

.cookie-policy-contact-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cookie-policy-container {
    padding: 15px;
  }

  .cookie-policy-title {
    font-size: 1.8rem;
  }

  .cookie-policy-subtitle {
    font-size: 1.3rem;
  }
}

.my-rates-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-rates-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.rate-accordion {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.rate-accordion-summary {
  background-color: #f5f5f5;
  border-radius: 8px;
}

.rate-name {
  font-size: 1.25rem;
  font-weight: 500;
}

.rate-accordion-details {
  background-color: #fafafa;
  border-radius: 8px;
}

.rate-table-container {
  margin-top: 10px;

  .MuiTableCell-root {
    font-size: 1rem;
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .my-rates-container {
    padding: 10px;
  }

  .rate-accordion {
    margin-bottom: 8px;
  }

  .rate-name {
    font-size: 1rem;
  }

  .rate-table-container .MuiTableCell-root {
    font-size: 0.875rem; /* Slightly smaller text for mobile */
  }
}
