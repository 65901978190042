.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #6a1b9a;
  color: white;
  overflow: auto;
  z-index: 1000;  // Asegura que el sidebar esté en la capa más alta

  .MuiListItem-button {
    color: white;
    &:hover {
      background-color: #4a148c;
    }
  }

  .MuiListItemIcon-root {
    color: white;
  }
}

.content {
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 250px);  // Ajuste del ancho para respetar la barra lateral

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 56px; // Ajuste para el AppBar
    width: 100%; // Ajuste del ancho para móviles
  }
}

.dashboard {
  display: flex;
  min-height: 100vh; // Asegura que ocupe toda la altura de la pantalla
}



.footer {
  margin-top: auto;
  background-color: #4a148c;
  color: white;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  margin: 5px 0;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.welcome-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  width: 100%; 
}

.welcome-message-content {
  text-align: center;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 0 auto; /* Asegura que el mensaje de bienvenida esté centrado horizontalmente */
}

.welcome-message-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.welcome-message-subtitle {
  font-size: 1.25rem;
  color: #666;
}


.sidebar-welcome {
  padding: 10px;
  &-title {
    font-size: 1.25rem;  // Tamaño de fuente más grande para resaltar el mensaje de bienvenida
    font-weight: bold;
    margin: 0;
  }

  &-role {
    font-size: 1rem;  // Tamaño de fuente ligeramente más pequeño para el rol
    margin-top: 5px;  // Reducir el espacio superior
    margin-bottom: 0;  // Eliminar el espacio inferior
    line-height: 1.2;  // Ajustar la altura de línea para hacer el texto más compacto
  }
}
