body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

/* Estilos Globales */
.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Estilos para el Login */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .MuiPaper-root {
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .MuiAvatar-root {
    background-color: #3f51b5;
  }

  .MuiButton-containedPrimary {
    background-color: #007bff;
    &:hover {
      background-color: #0056b3;
    }
  }
}

/* Estilos para ForgotPassword */
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;

  .MuiBox-root {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .MuiTypography-h5 {
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

  .MuiTextField-root {
    margin-bottom: 1rem;
  }

  .MuiAlert-root {
    margin-bottom: 1rem;
  }

  .MuiButton-containedPrimary {
    background-color: #1976d2;
    padding: 10px;
    font-size: 1rem;
    &:hover {
      background-color: #1565c0;
    }
  }
}

@media (max-width: 600px) {
  .forgot-password-container {
    padding: 10px;
  }

  .MuiBox-root {
    padding: 1.5rem;
  }

  .MuiButton-containedPrimary {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Estilos para ResetPassword */
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;

  .MuiBox-root {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .MuiTypography-h5 {
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

  .MuiTextField-root {
    margin-bottom: 1rem;
  }

  .MuiAlert-root {
    margin-bottom: 1rem;
  }

  .MuiButton-containedPrimary {
    background-color: #1976d2;
    padding: 10px;
    font-size: 1rem;
    &:hover {
      background-color: #1565c0;
    }
  }
}

@media (max-width: 600px) {
  .reset-password-container {
    padding: 10px;
  }

  .MuiBox-root {
    padding: 1.5rem;
  }

  .MuiButton-containedPrimary {
    padding: 8px;
    font-size: 0.9rem;
  }
}
